import errorSvg from "@/assets/images/img-404.svg";
import logo from "@/assets/images/logo.svg";
import Image from "next/image";
import Link from "next/link";
function Error404Page() {
  return (
    <div className="outer__inner">
      <div className="errorpage">
        <div className="errorpage__header">
          <Image
            className="errorpage__header_logo"
            src={logo}
            alt="logo"
            height={120}
            width={120}
          />
        </div>
        <div>
          <div className="errorpage__title">404</div>
          <div className="errorpage__title">Not Found</div>
        </div>

        <div className="errorpage__icon">
          <Image
            className="errorpage__icon_img"
            src={errorSvg}
            alt="Erorr 404"
          />
        </div>

        <div className="errorpage__description">
          We’ve explored deep and wide, but we can’t find the page you were
          looking for.
          <Link className="errorpage__button" href="/">
            {" "}
            Go to Home Page{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Error404Page;
